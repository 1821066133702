/**
 *  Storage Key 상수
 */
export class StorageKey {

  /**
   *  KEY 값은 의미를 알 수 없는 값을 사용, 정보 누출된 경우라도 문제가 없도록 한다 -> VALUE 역시 암호화 상태로 저장된다.
   */

  // APP 최초 방문
  // public APP_USER_VISITED: string = 'A_U_V_D';

  // 이메일 인증 요청시 입력된 이메일
  public USER_AUTH_EMAIL: string = 'E_U_A_E';

  // 비밀번호 찾기 요청시 입력된 이메일
  public USER_AUTH_PASSWORD_EMAIL: string = 'E_U_A_P_E';

  // 비밀번호 재살정 요청시 입력된 비밀번호
  public USER_RESET_PASSWORD_EMAIL: string = 'E_U_R_P_E';

  // 수거처리 상세 화면 back url 정보
  public COLLECTION_DETAIL_BACK_PAGE: string = 'E_C_D_B_P';

  // 큐레이션 상세 화면 back url 정보
  public CURATION_DETAIL_BACK_PAGE: string = 'E_C2_D_B_P';



  // 로그인 당시 사용한 입력 정보 (ID/PW)
  public USER_LOGIN_INFO: string = 'E_U_L_I_2';

  // 약관 선택 화면에서 사용
  public JOIN_INFO_AGREE: string = 'E_J_I_A';

  // 약관 동의 항목 중 마케팅 정보 수신 동의 - 항목포함 - Push / SMS / Email
  public JOIN_MARKETING_INFO_AGREE: string = 'E_J_M_I_A';

  // 비밀번호 찾기 - 입력정보 와 결과
  public ACCOUNT_PW_INFO_RESULT: string = 'E_A_P_I_R';

  // 알림 대상 UUID (NOTIFICATIONS_ID)
  public DETAIL_TARGET_NOTIFICATIONS_ID: string = 'E_D_T_N_I';
}

/**
 * Storage 관련 상수
 */
export class StorageConstant {

  // Storage key
  public static KEY: StorageKey = new StorageKey();
}


