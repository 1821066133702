/* tslint:disable */
import { Component, HostListener, OnInit, NgZone, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { CustomEventConstant } from '@common/constant/custom-event-constant';
import { NavController } from '@ionic/angular';
import { UseGpsDataConfig } from '@common/config/use-gps-data-config';
import { GpsInfoModel } from '@common/model/gps-info.model';
import { UseSettingConfig } from '@common/config/use-setting-config';
import { CommonUtil } from '@common/util/common.util';
// import { AtlanApiService } from '@common/service/atlan.api.service';
import { KakaoApiService } from '@common/service/kakao.api.service';
import { NAddressInfoModel } from '@common/model/n-address-info.model';

import { NotificationViewComponent } from './app-component/notification.view.component';
import { NotificationCheckComponent } from './app-component/notification.check.component';
import { ToastService } from '@common/service/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  @ViewChild('notificationView')
  notificationView: NotificationViewComponent;

  @ViewChild('notificationCheck')
  notificationCheck: NotificationCheckComponent;

  // public isViewAppPushMessage: boolean = false;

  constructor(private platform: Platform,
              private router: Router,
              private ngZone: NgZone,
              private navCtrl: NavController,
              private kakaoApiService: KakaoApiService,
              private toastService: ToastService) {

  }

  public ngOnInit(): void {
    this.platform.ready().then(() => {
      window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: (data) => this.angularFunctionCalled(data) };
      this.initializeApp();
    });
  }

  public viewNotificationEvent(event): void{
    if (this.notificationCheck === null || this.notificationCheck === undefined) {
      return;
    }
    const param: any = {};
    param.NOTIFICATIONS_ID = event;
    this.notificationCheck.checkMessageData(param);
  }

  private setPlatformType(data: any): void {
    UseSettingConfig.PARENT_PLATFORM_TYPE = data[0];
    UseGpsDataConfig.GPS_TYPE = data[0];
    // call GPS data
    CommonUtil.webKitCall('gps');
  }
  private setPlatformOs(data: any): void {
    UseSettingConfig.PARENT_PLATFORM_OS = data[0];
  }

  private setFcmToken(data: any): void {
    let token: string = data[0];
    token = token.replace(/\$exntu\$/g, '_');
    UseSettingConfig.APP_FCM_TOKEN = token;
  }

  private setGpsData(data: string[]): void {
    if(data === null) {
      return;
    }
    if(data.length < 3) {
      return;
    }
    if (UseGpsDataConfig.APP_GPS_DATA === null || UseGpsDataConfig.APP_GPS_DATA === undefined) {
      UseGpsDataConfig.APP_GPS_DATA = [];
    }
    if (UseGpsDataConfig.APP_GPS_DATA.length > 100) {
      UseGpsDataConfig.APP_GPS_DATA.splice(0,10);
    }
    const maxLength: number = 20;
    let latitude: string = data[0];
    let longitude: string = data[1];
    if (latitude === null) {
      latitude = ''
    }
    if (longitude === null) {
      longitude = ''
    }
    if (latitude.length > maxLength) {
      latitude = latitude.substr(0,maxLength);
    }
    if (longitude.length > maxLength) {
      longitude = longitude.substr(0,maxLength);
    }
    const gpsInfoModel: GpsInfoModel = new GpsInfoModel();
    gpsInfoModel.latitude = latitude;
    gpsInfoModel.longitude = longitude;
    gpsInfoModel.timestamp = data[2];
    gpsInfoModel.address = '';
    this.searchAddressUseWgs(gpsInfoModel);
  }

  private searchAddressUseWgs(gpsInfoModel: GpsInfoModel): void {
    const lan: string = gpsInfoModel.latitude;
    const lon: string = gpsInfoModel.longitude;

    let data: any = null;
    let apiResult: boolean = false;

    this.kakaoApiService.searchAddressUseWgs(lan, lon).subscribe(response => {
      if (response) {
        if (response.hasOwnProperty('data')) {
          data = JSON.parse(response.data);
          if (data.hasOwnProperty('success')) {
            apiResult = data.success;
          }
        }
      }
      if (apiResult !== true) {
        return;
      }

      const list: any[] = data.output.RS.list;
      if (list !== null && list.length > 0) {
        const obj: NAddressInfoModel = list[0].ADDRESS as NAddressInfoModel;
        let addstr: string = '';
        addstr = obj.ROAD_ADDRESS;
        if (obj.ADDRESS_TYPE !== '1') {
          addstr = obj.JIBUN_ADDRESS;
        }

        if (addstr !== '') {
          gpsInfoModel.address = addstr;
        }
      }
      UseGpsDataConfig.APP_GPS_DATA.push(gpsInfoModel);
    },error => {
      UseGpsDataConfig.APP_GPS_DATA.push(gpsInfoModel);
    });
  }


  private viewNotification(data: any): void {
    const param: any = {};
    param.TITLE = data[0].replace(/\$exntu\$/g, '_');
    param.BODY = data[1].replace(/\$exntu\$/g, '_');
    param.NOTIFICATIONS_ID = data[2].replace(/\$exntu\$/g, '_');
    this.notificationView.setMessageData(param);
  }

  private goNotification(data: any): void {
    const param: any = {};
    param.NOTIFICATIONS_ID = '';
    try {
      param.NOTIFICATIONS_ID = data[0].replace(/\$exntu\$/g, '_');
    }catch (e) {
      //
    }
    if (param.NOTIFICATIONS_ID === '') {
      param.NOTIFICATIONS_ID = 'exntu';
    }



    this.notificationView.hiddenNotification();
    this.notificationCheck.checkMessageData(param);
  }


  private goExpireNotification(data: any): void {
    const param: any = {};
    param.NOTIFICATIONS_ID = '';
    try {
      param.NOTIFICATIONS_ID = data[0].replace(/\$exntu\$/g, '_');
    }catch (e) {
      //
    }
    if (param.NOTIFICATIONS_ID === '') {
      param.NOTIFICATIONS_ID = 'exntu';
    }
    this.notificationCheck.expireMessageData(param);
  }


  private curationToastMessage(data: any): void {
    const type: string = data[0]
    if (type === 'success') {
      this.toastService.showSuccessTopToast('큐레이션 파일이 저장되었습니다');
    } else if (type === 'error') {
      this.toastService.showErrorTopToast('큐레이션 파일 저장에 실패했습니다');
    }
  }


  private angularFunctionCalled(datastring: string): void {
    if(datastring === null) {
      return;
    }
    const dataSplit: string[] = datastring.split('_');
    if (dataSplit === null || dataSplit.length < 1) {
      return;
    }
    const command: string = dataSplit[0];
    const valuesLength: number = 4;
    const values: string[] = [];

    for (let i: number = 0; i < valuesLength; i++) {
      values.push(null);
    }

    for (let i: number = 0; i < valuesLength; i++) {
      if (dataSplit.length > (i + 1)) {
        values[i] = dataSplit[i + 1];
      }
    }

    switch (command)
    {
      case 'parentPlatformType':
        this.setPlatformType(values)
        break;

      case 'parentPlatformOs':
        this.setPlatformOs(values);
        break;

      case 'gps':
        this.setGpsData(values);
        break;

      case 'fcm':
        this.setFcmToken(values);
        break;

      case 'viewNotification':
        if (this.notificationView === null || this.notificationView === undefined) {
          return;
        }
        this.viewNotification(values);
        break;

      case 'goNotification':
        if (this.notificationCheck === null || this.notificationCheck === undefined) {
          return;
        }
        this.goNotification(values);
        break;

      case 'goExpireNotification':
        this.goExpireNotification(values);
        break;


      case 'curationToastMessage':
        this.curationToastMessage(values);
        break;

    }
  }

  private initializeApp(): void {
    UseSettingConfig.NOTIFIACTION_LISTEN_ENABLE = false;
    CommonUtil.webKitCall('getPlatformType');
  }
}
