import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonUserService } from '@common/service/common.user.service';
import { LayoutNaviConstant } from '@common/constant/layout-constant';
import { UseSettingConfig } from '@common/config/use-setting-config';

@Injectable()
export class AuthUserGuard implements CanActivate {
  constructor(private router: Router,
              private commonUserService: CommonUserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const enabledPromise: Promise<boolean> = this.commonUserService.isLoggedInUseGuard().then((data) => data).catch(() => false);
    enabledPromise.then((data) => {
      if (!data) {
        UseSettingConfig.NOTIFIACTION_LISTEN_ENABLE = false;
        this.router.navigateByUrl('home');
      } else {
       //
        UseSettingConfig.NOTIFIACTION_LISTEN_ENABLE = true;

        let ndata: any = null;
        let apiResult: boolean = false;
        this.commonUserService.getNotificationListCount().subscribe(response => {
          if(response) {
            if (response.hasOwnProperty('data')) {
              ndata = JSON.parse(response.data);
              if (ndata.hasOwnProperty('success')) {
                apiResult = ndata.success;
              }
            }
          }
          if (apiResult !== true) {
            return;
          }
          const list: any[] = ndata.output.RS.list;
          if (list !== null) {
            LayoutNaviConstant.MY_NEW_NOTIFICATION_NUMBER = list.length;
          }
        }, error => {

        })
      }
    });
    return enabledPromise;
  }
}

