import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonUserService } from '@common/service/common.user.service';
import {UseSettingConfig} from "@common/config/use-setting-config";

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(private router: Router,
              private commonUserService: CommonUserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const enabledPromise: Promise<boolean> = this.commonUserService.isLoggedInUseGuard().then((data) => !data).catch(() => true);
    enabledPromise.then((data) => {
      if (!data) {
        // UseSettingConfig.NOTIFIACTION_LISTEN_ENABLE = false;
        this.router.navigateByUrl('home');
      } else {
        //
      }
    });
    return enabledPromise;
  }
}
