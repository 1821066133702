import { Component, ElementRef, Injector, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { StorageConstant } from '@common/constant/storage-constant';
import { CommonUserService } from '@common/service/common.user.service';
import { CryptoService } from '@common/service/crypto.service';
import { UseSettingConfig } from '@common/config/use-setting-config';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-notification-check',
  templateUrl: './notification.check.component.html'
})
export class NotificationCheckComponent implements OnInit, OnDestroy {

  @Output()
  public checkNotificationEvent = new EventEmitter();

  constructor(protected elementRef: ElementRef,
              protected injector: Injector,
              protected router: Router,
              protected commonUserService: CommonUserService,
              protected cryptoService: CryptoService,
              protected storage: Storage,
              protected navCtrl: NavController,
              protected cookieService: CookieService) {
  }

  public ngOnInit(): void {
    //
  }
  public ngOnDestroy(): void {
    //
  }

  public checkMessageData(checkData: any): void {
    if (UseSettingConfig.NOTIFIACTION_LISTEN_ENABLE === false) {
      this.makeCookie(checkData, false);
    } else {
      this.checkLogin(checkData);
    }

  }
  public expireMessageData(checkData: any): void {
    if (UseSettingConfig.NOTIFIACTION_LISTEN_ENABLE === false) {
      this.makeCookie(checkData, false);
    } else {
      this.checkLogin(checkData);
    }
  }

  /**
   * 로그인 유무 확인
   */
  private checkLogin(checkData: any): void {
    this.commonUserService.isLoggedIn().subscribe(response => {
      let success: boolean = false;
      const datakey: string = 'data';
      const successkey: string = 'success';
      try{
        const jsonData: any = JSON.parse(response[datakey]);
        if(jsonData.hasOwnProperty(successkey)) {
          success = jsonData[successkey];
        }
      }catch (error) {
        //
      }
      if (success === true) {
        if (UseSettingConfig.NOTIFIACTION_LISTEN_ENABLE === true) {
          this.goNotification(checkData);
        } else {
          this.checkRouter(checkData);
        }
      } else {
        this.checkRouter(checkData);
      }
    }, error => {

    });
  }

  /**
   * checkRouter
   */
  private checkRouter(checkData: any): void {
    const url: string = this.router.url;
    if (url ==='' || url === 'home') {
      this.makeCookie(checkData, false);
    } else {
      this.makeCookie(checkData, true);
    }
  }

  /**
   * makeCookie
   */
  private makeCookie(checkData: any , isGo: boolean): void {
    const expirePushId: string = checkData.NOTIFICATIONS_ID;
    this.cookieService.set(UseSettingConfig.EXPIRE_NOTIFIACTION_COOKIE_NAME, expirePushId, 0, '/');
    if(isGo===true) {
      this.navCtrl.navigateForward('home');
    }
  }

  /**
   * 알림 페이지로 이동 --> layout/my-notification
   */
  private goNotification(checkData: any): void {
    this.cookieService.delete(UseSettingConfig.EXPIRE_NOTIFIACTION_COOKIE_NAME);
    const dataString: string =  JSON.stringify(checkData);
    this.setStorage(StorageConstant.KEY.DETAIL_TARGET_NOTIFICATIONS_ID, dataString).then(() => {
      // const url: string = 'layout/my-notification?tc'+ new Date().getTime();
      const url: string = 'none-layout/my-notification?tc='+ new Date().getTime() + '&bpage=layout-home';
      this.navCtrl.navigateForward(url);
    });
  }

  /**
   * Storage
   */
  async setStorage(key: string, value: any): Promise<any> {
    try {
      let encryptValue = null;
      if (value !== null) {
        encryptValue = this.cryptoService.encrypt(value);
      }
      await this.storage.set(key, encryptValue);
      return true;
    } catch (reason) {
      return false;
    }
  }
}
