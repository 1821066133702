import { Injectable,Injector } from '@angular/core';
import { AbstractService } from '@common/service/abstract.service';
import { Storage } from '@ionic/storage';
import { StorageConstant } from '@common/constant/storage-constant';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CommonUserService extends AbstractService {

  secretKey = 'eCoYaUser$9652exntu!';


  constructor(protected injector: Injector,
              protected storage: Storage) {
    super(injector);
  }

  /**
   * 로그인
   */
  public login (id: string, passWd:string) {
    // URL
    const url = this.API_URL + 'svc/user/login';
    const param: any = {ID: id, PASSWD: passWd};

    const params = new HttpParams()
      .set('ID', id)
      .set('PASSWD', passWd);
    return this.postWithoutAuthLogin(url, params);
  }


  /**
   * 로그아웃
   */
  public logout <T=any>() {
    // URL
    const url = this.API_URL + 'svc/user/logout';
    return this.post(url);
  }



  /**
   * 로그인 유무 확인 - HOME 애서 사용
   */
  public isLoggedIn() {
    // DATA
    const IN_ARR: any[] = [{}];
    const IN: any = {'IN': IN_ARR};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_LOGIN_GUARD/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * 로그인 유무 확인 - GUARD 에서 사용
   */
  public isLoggedInUseGuard(): Promise<boolean> {
    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_LOGIN_GUARD/';

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const IN_ARR: any[] = [{}];
    const IN: any = {'IN': IN_ARR};
    const INPUT: any = {'input' : IN};

    return this.http.post(url, INPUT, {headers: headers})
      .toPromise()
      .then((response) => {
        let success: boolean = false;
        const datakey: string = 'data';
        const successkey: string = 'success';
        try{
          const jsonData: any = JSON.parse(response[datakey]);
          if(jsonData.hasOwnProperty(successkey)) {success = jsonData[successkey];}
        }catch (error) {
          //
        }
        if(success === true) {
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      })
      .catch(error => Promise.resolve(false));
  }

  /**
   * 사용자 비밀번호 확인
   */
  public checkUserPassword(target: string) {
    // DATA
    const IN: any = {'IN': [{'PASSWORD': target}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_PW_CHECK/';
    return this.post(url, undefined, INPUT);
  }

  /**
   * 사용자 비밀번호 변경 - 로그인 이후
   */
  public patchUserPassWd(target: string) {
    // DATA
    const IN: any = {'IN': [{'PASSWORD': target}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_PW_UPDATE/';
    return this.post(url, undefined, INPUT);
  }



  /**
   * 사용자 연락처 변경
   */
  public patchUserPhone(target1: string, target2: string) {
    // DATA
    const IN: any = {'IN': [{'PHONE_NO': target1, 'PHONE_NO2': target2}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_PHONE_UPDATE/';
    return this.post(url, undefined, INPUT);
  }



  /**
   * 사용자 기본 정보 - 이름,연락처, 마케팅 정보 동의
   */
  public getUserBasicInfo() {
    // DATA
    const IN: any = {'IN': [{}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_BASIC_INFO/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * 사용자 상세 정보 - 이름,연락처, 마케팅 정보 동의
   */
  public getUserDetailInfo() {
    // DATA
    const IN: any = {'IN': [{}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_DETAIL_INFO/';
    return this.post(url, undefined, INPUT);
  }

  /**
   * (사용자) 회사 배출지 리스트 (기본정보)
   */
  public getDischrgeAreaList() {

    // DATA
    const IN: any = {'IN': [{}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_DISCHARGE_AREA_LIST/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * (사용자) 회사 배출지 리스트 (수거신청용도))
   */
  public getDischrgeAreaListForRequest() {

    // DATA
    const IN: any = {'IN': [{}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_DISCHARGE_AREA_LIST_FOR_REQUEST/';
    return this.post(url, undefined, INPUT);
  }



  /**
   * (사용자) 회사 배출지 (상세정보)
   */
  public getDischrgeAreaDetail(target: string) {

    // DATA
    const IN: any = {'IN': [{'DISCHARGE_AREA_ID':target}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_DISCHARGE_AREA_DETAIL/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * (사용자) 회사 배출지 등록
   */
  public setDischrgeArea(data: any, datalist: any[]) {

    // DATA
    const IN_L: any = {'IN': [data], 'IN_WASTE': datalist};
    const INPUT: any = {'input' : IN_L};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_DISCHARGE_AREA_INSERT/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * (사용자) 회사 배출지 수정 (상단영역)
   */
  public patchDischrgeAreaTop(data: any) {

    // DATA
    const IN: any = {'IN': [data]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_DISCHARGE_AREA_TOP_UPDATE/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * (사용자) 회사 배출지 수정 (하단영역)
   */
  public patchDischrgeAreaBottom(data: any, datalist: any[]) {

    // DATA
    const IN_L: any = {'IN': [data], 'IN_WASTE': datalist};
    const INPUT: any = {'input' : IN_L};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_DISCHARGE_AREA_BOTTOM_UPDATE/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * 알림 설정 (PUSH) 수정
   */
  public patchPushYn(target: string) {
    // DATA
    const IN: any = {'IN': [{'EVENT_APP_AGREEMENT_YN': target}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_EVENT_APP_UPDATE/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * 알림 설정 (SMS) 수정
   */
  public patchSmsYn(target: string) {
    // DATA
    const IN: any = {'IN': [{'EVENT_SMS_AGREEMENT_YN': target}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_EVENT_SMS_UPDATE/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * 알림 설정 (EMAIL) 수정
   */
  public patchEmailYn(target: string) {
    // DATA
    const IN: any = {'IN': [{'EVENT_EMAIL_AGREEMENT_YN': target}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_EVENT_EMAIL_UPDATE/';
    return this.post(url, undefined, INPUT);
  }




  /**
   * 공통코드 리스트
   */
  public getCommonTpGroupList(data: any) {
    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_CODE_LIST/';
    return this.post(url, undefined, data);
  }




  /**
   * TEMP DATA 생성
   */
  public tempInsert(data: any) {
    // DATA
    const param: any = {};
    param.CONTENTS = data.CONTENTS;
    param.TEMP_CONTENTS_TP = data.TEMP_CONTENTS_TP;
    param.MEMBER_TP = 'USER';

    // DATA
    const IN: any = {'IN': [param]};
    const INPUT: any = {'input' : IN};
    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_TEMP_CONTENTS_INSERT/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * TEMP DATA 업데이트
   */
  public tempUpdate(data: any) {
    // DATA
    const param: any = {};
    param.ID = data.ID;
    param.CONTENTS = data.CONTENTS;

    // DATA
    const IN: any = {'IN': [param]};
    const INPUT: any = {'input' : IN};
    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_TEMP_CONTENTS_UPDATE/';
    return this.post(url, undefined, INPUT);
  }

  /**
   * TEMP DATA 조회
   */
  public tempSelect(id: string) {
    // DATA
    const param: any = {};
    param.ID = id;

    // DATA
    const IN: any = {'IN': [param]};
    const INPUT: any = {'input' : IN};
    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_TEMP_CONTENTS_SELECT/';
    return this.post(url, undefined, INPUT);
  }


  /**
   * TEMP DATA 삭제
   */
  public tempDelete(id: string) {
    // DATA
    const param: any = {};
    param.ID = id;

    // DATA
    const IN: any = {'IN': [param]};
    const INPUT: any = {'input' : IN};
    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_TEMP_CONTENTS_DELETE/';
    return this.post(url, undefined, INPUT);
  }



  /**
   * 이메일 인증 요청
   */
  public authEmailRequest(email: string) {
    // DATA
    const IN: any = {'IN': [{'EMAIL': email}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_AUTHENTICATION_EMAIL_REQUEST';
    return this.postWithoutAuth(url, INPUT);
  }


  /**
   * 이메일 인증 요청
   */
  public authEmail(authKey: string) {
    // DATA
    const IN: any = {'IN': [{'EMAIL_AUTHENTICATION_KEY': authKey}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_AUTHENTICATION_EMAIL';
    return this.postWithoutAuth(url, INPUT);
  }




  public resetPwEmailRequest(email: string) {
    // DATA
    const IN: any = {'IN': [{'EMAIL': email}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_RESET_PW_EMAIL_REQUEST';
    return this.postWithoutAuth(url, INPUT);

  }



  public pwEmailCheck(authKey: string) {
    // DATA
    const IN: any = {'IN': [{'EMAIL_AUTHENTICATION_KEY': authKey}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_RESET_PW_EMAIL_CHECK';
    return this.postWithoutAuth(url, INPUT);
  }


  public pwEmailConfirm(authKey: string, passwd: string) {
    // DATA
    const IN: any = {'IN': [{'EMAIL_AUTHENTICATION_KEY': authKey, 'PASSWORD': passwd}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'USER_RESET_PW_EMAIL_CONFIRM';
    return this.postWithoutAuth(url, INPUT);
  }



  /**
   * 약관 조회
   */
  public getTerms(target: string) {
    // DATA
    const IN: any = {'IN': [{'TC_TP': target}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_TC_CONTENTS';
    return this.post(url, undefined, INPUT);
  }


  /**
   * PUSH 토큰 추가
   */
  public setFcmToken(data: any) {

    const param: any = {};
    param.DEVICE_TOKEN = data.DEVICE_TOKEN;
    param.DEVICE_TP = data.DEVICE_TP;

    // DATA
    const IN: any = {'IN': [param]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_FCM_TOKEN_INSERT';
    return this.post(url, undefined, INPUT);
  }


  /**
   * PUSH 토큰 제거
   */
  public deleteFcmToken(data: any) {
    const param: any = {};
    param.DEVICE_TOKEN = data.DEVICE_TOKEN;
    param.DEVICE_TP = data.DEVICE_TP;
    // DATA
    const IN: any = {'IN': [param]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_FCM_TOKEN_DELETE';
    return this.post(url, undefined, INPUT);
  }

  /**
   * 알림 리스트 - 카운트 수
   */
  public getNotificationListCount() {
    // DATA
    const IN: any = {'IN': [{}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'COMMON_NOTIFICATION_LIST_COUNT';
    return this.post(url, undefined, INPUT);
  }
}

